export const emailSettings = {
  serviceId: "service_toeokhv",
  appName: "PlaceAI",
  templateId: "template_6cz7b9k",
  userId: "nHpfuBMul5tdGXMfm",
  accessToken: "YnvxImnNvC_VsuASMAJ6a",
};

/*
  DOCUMENTATION

  The contact form uses EmailJS to send emails. All you need to do is signup to EmailJS at https://www.emailjs.com/
  and in your new account you will find the details to complete the above.

*/
